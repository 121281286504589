import { PayloadAction } from '@reduxjs/toolkit';
import { isObj } from './toolUtls';
import store, { RootState, RootStateNamespace } from '@/store';
import { Reducer } from 'react';

export const mapBaseReducers = (initialState?: any): Record<string, Reducer<any, any>> => {
  return {
    setState: (state: RootState, action: PayloadAction) => {
      const payload = action.payload;

      const coverState = (state: Record<any, any>, partialState: any) => {
        Object.keys(partialState).forEach((key) => {
          if (isObj(state[key]) && isObj(partialState[key])) {
            coverState(state[key], partialState[key]);
          } else {
            // @ts-ignore
            state[key] = partialState[key];
          }
        });
      };

      coverState(state, payload);
    },
    resetState: () => {
      return initialState ? initialState : {}
    }
  };
};

export const creatSetState = (namespace: RootStateNamespace) => {
  return <T>(state: T) => {
    store.dispatch({
      type: `${namespace}/setState`,
      payload: state,
    });
  };
};

export const createResetState = (namespace: RootStateNamespace) => {
  return () => {
    store.dispatch({
      type: `${namespace}/resetState`,
    });
  };
}
