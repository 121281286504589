import { createSlice } from '@reduxjs/toolkit';
import { createResetState, creatSetState, mapBaseReducers } from '@/utils/reduxUtils';
import { ITeamItem } from '@/services/team-management';
import { KUAICJ_TEAM_ID } from '@/config/constants';

interface ArrangeInvigilationInitialState {
  currentTeam: ITeamItem | undefined;
}

let defaultCurrentTeam: ITeamItem | undefined;
try {
  defaultCurrentTeam = JSON.parse(localStorage.getItem(KUAICJ_TEAM_ID) || 'null');
} catch (e) {
  console.log(e);
  localStorage.removeItem(KUAICJ_TEAM_ID);
}

const initialState: ArrangeInvigilationInitialState = {
  currentTeam: defaultCurrentTeam || undefined,
};

const teamManagementSlice = createSlice({
  name: 'teamManagement',
  initialState: initialState,
  reducers: {
    ...mapBaseReducers(initialState),
  },
});

export const setTeamManagementState = creatSetState('teamManagement');
export const resetTeamManagementState = createResetState('teamManagement');

export default teamManagementSlice;
