import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import base from '@/services/base';
import userSlice from '@/store/user';
import feedbackSlice from '@/store/feedback';
import teamManagementSlice from '@/store/teamManagement';
import scoreSlice from './score';

export const store = configureStore({
  reducer: {
    user: userSlice.reducer,
    feedback: feedbackSlice.reducer,
    teamManagement: teamManagementSlice.reducer,
    score: scoreSlice.reducer,
    [base.reducerPath]: base.reducer,
  },
  middleware: (getDefaultMiddleware) => {
    const defaultMiddleware = getDefaultMiddleware({
      serializableCheck: false, // 由于存在大块数据（400kb），禁用serializable中间件
    });
    return defaultMiddleware.concat(base.middleware);
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type RootStateNamespace = keyof RootState;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
