import { ReactNode } from 'react';
import { Provider as StoreProvider } from 'react-redux';
import store from '@/store';
import { ConfigProvider as AntdProvider } from 'antd';
import zhCN from 'antd/locale/zh_CN';
import {
  StyleProvider,
  legacyLogicalPropertiesTransformer,
} from '@ant-design/cssinjs';
import { MessageProvider, ModalProvider } from '@/utils/antdUtils';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import duration from 'dayjs/plugin/duration';
import ErrorBoundary from '@/components/ErrorBoundary';
import report from '@/utils/report';

dayjs.extend(duration);
dayjs.locale('zh-cn');

report.init()

export function rootContainer(container: ReactNode) {
  return (
    <ErrorBoundary>
      {/* 兼容360浏览器 */}
      <StyleProvider
        transformers={[legacyLogicalPropertiesTransformer]}
        hashPriority="high"
      >
        {/* antd全局配置 */}
        <AntdProvider
          locale={zhCN}
          theme={{
            token: { colorPrimary: '#37AEB9' },
          }}
        >
          {/* message获取context */}
          <MessageProvider>
            {/* modal获取context */}
            <ModalProvider>
              {/* store状态管理 */}
              <StoreProvider store={store}>{container}</StoreProvider>
            </ModalProvider>
          </MessageProvider>
        </AntdProvider>
      </StyleProvider>
    </ErrorBoundary>
  );
}

export function render(oldRender: () => ReactNode) {
  oldRender();
}
