import { createSlice } from '@reduxjs/toolkit';
import {
  createResetState,
  creatSetState,
  mapBaseReducers,
} from '@/utils/reduxUtils';
import { ModalFuncProps } from 'antd/es/modal/Modal';

export interface FeedbackState {
  globalMessageError?: {
    level: 'error' | 'warning';
    message: string;
  };
  globalModalError?: {
    level: 'error' | 'warning' | 'confirm' | 'info';
    title?: string;
    message?: string;
    onOk?: () => Promise<any> | void;
    modalConfig?: ModalFuncProps
  };
}

const initialState: FeedbackState = {
  globalMessageError: undefined,
  globalModalError: undefined,
};

const feedbackSlice = createSlice({
  name: 'feedback',
  initialState: initialState,
  reducers: {
    ...mapBaseReducers(initialState),
  },
});

export const setFeedbackState = creatSetState('feedback');
export const resetFeedbackState = createResetState('feedback');

export default feedbackSlice;
