import React from 'react';
import { Card, Button } from 'antd';

class ErrorBoundary extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false, errorName: '' };
  }

  static getDerivedStateFromError(error: any) {
    // 更新 state 使下一次渲染能够显示降级后的 UI
    return {
      hasError: true,
      errorName: error.name,
    };
  }

  render() {
    if (this.state.hasError) {
      if (this.state.errorName === 'ChunkLoadError') {
        return (
          <Card
            bodyStyle={{
              height: document.documentElement.offsetHeight - 144,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 40,
            }}
          >
            <div>页面资源已更新，请刷新页面后重试！</div>
            <Button
              size="large"
              style={{ width: 120 }}
              type="primary"
              onClick={() => window.location.reload()}
            >刷新</Button>
          </Card>
        );
      }
      return (
        <Card
          bodyStyle={{
            height: document.documentElement.offsetHeight - 144,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 40,
          }}
        >
          <div>页面加载失败，请刷新页面后重试！</div>
          <Button
            size="large"
            style={{ width: 120 }}
            type="primary"
            onClick={() => window.location.reload()}
          >刷新</Button>
        </Card>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
