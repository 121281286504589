/*
 * @Author: 吴永生 15770852798@163.com
 * @Date: 2023-11-13 16:40:56
 * @LastEditors: 吴永生 15770852798@163.com
 * @LastEditTime: 2023-12-08 14:52:25
 * @FilePath: /kuaichengji-web/src/store/user.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { createSlice } from '@reduxjs/toolkit';
import { creatSetState, mapBaseReducers } from '@/utils/reduxUtils';

type ICheckedBaseFieldArrType = 'className' | 'studentName' | 'studentNo';
type ICheckedLevelArrType = 'level' | 'score';
export interface ScoreState {
  // 基础字段
  checkedBaseFieldArr: ICheckedBaseFieldArrType[];
  // 科目&排名
  checkedSubjectArr: string[];
  // 总分&排名
  checkedTotalScoreArr: string[];
  // 等级赋分
  checkedLevelArr: ICheckedLevelArrType[];
  // 课程组合
  checkedCourseGrouplArr: string[];
  // 选科组合
  checkedSubjectGrouplArr: string[];
  // 总分和排名的id
  totalScoreAndRankIdArr: string[];
}

const initialState: ScoreState = {
  checkedBaseFieldArr: [],
  checkedSubjectArr: [],
  checkedTotalScoreArr: [],
  checkedLevelArr: [],
  checkedCourseGrouplArr: [],
  checkedSubjectGrouplArr: [],
  totalScoreAndRankIdArr: [],
};

const scoreSlice = createSlice({
  name: 'score',
  initialState: initialState,
  reducers: {
    ...mapBaseReducers(),
  },
});

export const setScoreState = creatSetState('score');

export default scoreSlice;
